"use client";
import Link from "next/link";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Settings } from "react-slick";

// Componente para el botón "anterior"
const PrevArrow = (props: any) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} z-50`}
      style={{ ...style, display: "block", left: "10px" }}
      onClick={onClick}
    >
      Anterior
    </div>
  );
};

// Componente para el botón "siguiente"
const NextArrow = (props: any) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} z-50`}
      style={{ ...style, display: "block", right: "10px" }}
      onClick={onClick}
    >
      Siguiente
    </div>
  );
};

const SliderSlick = () => {
  var settings: Settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 8000,
    pauseOnHover: true,
    swipeToSlide: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
  };
  return (
    <div className="slider-container">
      <Slider {...settings}>
        <div className="hs-carousel-slide">
          <div className="h-[30rem] md:h-[calc(100vh-106px)]  flex flex-col bg-[url('/slider/slide1.jpg')] bg-cover bg-[center] bg-no-repeat">
            <div className="mt-auto pr-5 md:ml-3 md:mb-3 w-full md:w-2/3 md:max-w-lg ps-5 pb-4 md:ps-10 md:pb-10 bg-light/70 rounded-none md:rounded-lg">
              <span className="block text-dark font-extrabold mt-4">
                ¿Tu menú todavía está en papel, PDF o imagen?
              </span>
              <span className="block text-dark text-lg md:text-2xl">
                Mejora la experiencia de tus clientes con un menú digital
                interactivo
              </span>
              <div className="mt-5">
                <Link
                  className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-xl bg-accent-500 border border-transparent text-light hover:bg-accent-600 focus:outline-none focus:bg-accent-100 disabled:opacity-50 disabled:pointer-events-none"
                  href="#contact"
                >
                  Empieza Gratis
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="hs-carousel-slide">
          <div className="h-[30rem] md:h-[calc(100vh-106px)]  flex flex-col bg-[url('/slider/slide2.jpg')] bg-cover bg-no-repeat bg-[70%_50%]">
            <div className="mt-auto pr-5 md:ml-3 md:mb-3 w-full md:w-2/3 md:max-w-lg ps-5 pb-4 md:ps-10 md:pb-10 bg-light/70 rounded-none md:rounded-lg">
              <span className="block text-dark font-extrabold mt-4">
                Fácil de Usar
              </span>
              <span className="block text-dark text-xl md:text-3xl">
                Menús accesibles y actualizados en tiempo real
              </span>
              <div className="mt-5">
                <Link
                  className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-xl bg-accent-500 border border-transparent text-light hover:bg-accent-600 focus:outline-none focus:bg-accent-100 disabled:opacity-50 disabled:pointer-events-none"
                  href="#contact"
                >
                  Suscríbete
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="hs-carousel-slide">
          <div
            className={`h-[30rem] md:h-[calc(100vh-106px)]  flex flex-col bg-[url('/slider/slide3.jpg')] bg-cover bg-[center] bg-no-repeat`}
          >
            <div className="mt-auto pr-5 md:ml-3 md:mb-3 w-full md:w-2/3 md:max-w-lg ps-5 pb-4 md:ps-10 md:pb-10 bg-light/70 rounded-none md:rounded-lg">
              <span className="block text-dark font-extrabold mt-4">
                Comunicación
              </span>
              <span className="block text-dark text-xl md:text-3xl">
                Tus clientes pueden llamar al mesero sin necesidad de instalar
                equipo adicional
              </span>
              <div className="mt-5">
                <Link
                  className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-xl bg-accent-500 border border-transparent text-light hover:bg-accent-600 focus:outline-none focus:bg-accent-100 disabled:opacity-50 disabled:pointer-events-none"
                  href="#contact"
                >
                  Empieza Gratis
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="hs-carousel-slide">
          <div
            className={`h-[30rem] md:h-[calc(100vh-106px)]  flex flex-col bg-[url('/slider/slide4.jpg')] bg-cover bg-[center] bg-no-repeat`}
          >
            <div className="mt-auto pr-5 md:ml-3 md:mb-3 w-full md:w-2/3 md:max-w-lg ps-5 pb-4 md:ps-10 md:pb-10 bg-light/70 rounded-none md:rounded-lg">
              <span className="block text-dark font-extrabold mt-4">
                Visibilidad
              </span>
              <span className="block text-dark text-xl md:text-3xl">
                Toma decisiones basadas en datos y conoce mejor la operación de
                tu restaurante
              </span>
              <div className="mt-5">
                <Link
                  className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-xl bg-accent-500 border border-transparent text-light hover:bg-accent-600 focus:outline-none focus:bg-accent-100 disabled:opacity-50 disabled:pointer-events-none"
                  href="#contact"
                >
                  Suscríbete
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Slider>
    </div>
  );
};

export default SliderSlick;
