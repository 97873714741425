"use client";
import React, { useEffect, useState } from "react";
import Input from "@/components/baseComponents/Input";
import wwwPricing from "@/config/www_pricing.json";

const PriceCalculator = () => {
  const pricingCards = JSON.parse(JSON.stringify(wwwPricing))["pricingCards"];
  const precio = pricingCards.filter(
    (card: { title: string }) => card.title === "Premium"
  )[0].dailyPrice;
  const [mesas, setMesas] = useState(0);
  const [costo, setCosto] = useState(0);
  useEffect(() => {
    setCosto(mesas * precio * 30);
  }, [mesas, precio]);
  return (
    <div className="max-w-[100%] md:max-w-[80%] mx-auto mt-5">
      <h2 className="text-light ">Calcula tu costo mensual</h2>
      <div className="flex items-center justify-center gap-3 bg-light rounded-lg p-5">
        <Input
          type="number"
          label="Número de mesas"
          labelClassName="text-dark"
          className=""
          value={mesas}
          onChange={(e) => {
            const value = e.target.value;
            setMesas(value !== "" ? Number(value) : 0);
          }}
        />
        <p className="text-dark">X ${precio}.00 MXN X 30 días =</p>
        <p className="text-dark text-lg font-bold">
          $ {costo}.00 MXN mensuales
        </p>
      </div>
    </div>
  );
};

export default PriceCalculator;
