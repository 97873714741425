import React from "react";

// Define the types for your component props
interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  labelClassName?: string;
  errorMessage?: string;
}

const CustomInput: React.FC<InputProps> = ({
  label,
  errorMessage,
  className,
  labelClassName,
  ...props
}) => {
  return (
    <div className="flex flex-col mb-4">
      {label && (
        <label
          htmlFor={props.id}
          className={`${labelClassName} block text-sm font-medium mb-2 `}
        >
          {label}
        </label>
      )}
      <input
        className={`py-3 px-4 block w-full border-gray-500 rounded-lg text-sm focus:border-primary-500 focus:ring-primary-500 dark:bg-dark dark:border-neutral-700 dark:text-light ${className}`}
        {...props}
      />
      {errorMessage && (
        <p className="mt-1 text-xs text-red-500">{errorMessage}</p>
      )}
    </div>
  );
};

export default CustomInput;
