"use client";
import React, { useEffect, useState } from "react";
import { useFormState } from "react-dom";
import { registerEarlyAdopterAction } from "@/data/actions/www-actions";
export default function ContactSection() {
  const initialState = {
    name: "",
    email: "",
    message: "",
  };
  const [state, formAction, pending] = useFormState(
    registerEarlyAdopterAction,
    initialState
  );
  const [thankYou, setThankYou] = useState(false);

  useEffect(() => {
    if (state.error === null) {
      setThankYou(true);
    }
  }, [state]);
  return (
    <div
      id="contact"
      className="grid grid-cols-1 lg:grid-cols-2 items-center px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto scroll-mt-10 bg-light"
    >
      <div className="max-w-xl lg:max-w-full mx-auto px-10">
        <div className="text-center">
          <h5 className="text-lg font-bold text-dark  ">
            ¡Transforma la experiencia en tu restaurante con WaiterMate!
          </h5>
          <p className="mt-3 text-dark text-center">
            ¿Quieres llevar el servicio al siguiente nivel y sorprender a tus
            clientes?
            <br />
            <br />
            Únete a nuestro <strong>Early Adopter Program</strong> y sé de los
            primeros en disfrutar de{" "}
            <strong>
              WaiterMate{" "}
              <span className="bg-secondary-500 py-1 px-2 text-xs border border-1 rounded-full">
                Premium
              </span>
            </strong>{" "}
            antes de su lanzamiento oficial.
          </p>
          <p className="mt-3 text-dark text-left pt-5">
            <strong>🎉 Inscríbete ahora y recibe beneficios exclusivos:</strong>
          </p>
          <ul className="bg-dark/70 text-left text-light p-3 rounded-lg my-3 px-8 shadow-lg">
            <li className="list-disc">Acceso anticipado a la app</li>
            <li className="list-disc">
              Funcionalidades premium gratis por 3 meses
            </li>
            <li className="list-disc">Soporte prioritario y personalizado</li>
          </ul>
        </div>
      </div>
      {thankYou && (
        <div className="max-w-xl mx-auto mt-8 bg-white rounded-lg p-6 text-center">
          <h2 className="text-xl font-semibold text-gray-800">
            ¡Gracias por tu interés en WaiterMate!
          </h2>
          <p className="mt-3 text-gray-600">
            Hemos recibido tu solicitud. Pronto te daremos acceso a la app.
          </p>
        </div>
      )}
      {!thankYou && (
        <div className="mt-12 max-w-lg mx-auto bg-white rounded-lg overflow-hidden shadow-lg">
          <div className="flex flex-col border  p-4 sm:p-6 lg:p-8 ">
            <h2 className="mb-8 text-xl font-semibold text-gray-800 ">
              Llena el formulario para reservar tu lugar en el programa de Early
              Adopters
            </h2>

            <form action={formAction}>
              <div className="grid gap-4 lg:gap-6">
                <div className="grid grid-cols-1 gap-4 lg:gap-6">
                  <div className="w-full">
                    <label
                      htmlFor="hs-name-contacts-1"
                      className="block mb-2 text-sm text-dark font-medium "
                    >
                      Nombre
                    </label>
                    <input
                      type="text"
                      name="hs-name-contacts-1"
                      id="hs-name-contacts-1"
                      className="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-primary-500 focus:ring-primary-600 disabled:opacity-50 disabled:pointer-events-none"
                    />
                  </div>
                </div>

                <div className="grid grid-cols-1  gap-4 lg:gap-6">
                  <div>
                    <label
                      htmlFor="hs-email-contacts-1"
                      className="block mb-2 text-sm text-dark font-medium "
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      name="hs-email-contacts-1"
                      id="hs-email-contacts-1"
                      autoComplete="email"
                      className="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-primary-500 focus:ring-primary-500 disabled:opacity-50 disabled:pointer-events-none "
                    />
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="hs-about-contacts-1"
                    className="block mb-2 text-sm text-gray-700 font-medium "
                  >
                    Cuéntanos un poco sobre tu restaurante
                  </label>
                  <textarea
                    id="hs-about-contacts-1"
                    name="hs-about-contacts-1"
                    rows={4}
                    className="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-primary-500 focus:ring-primary-500 disabled:opacity-50 disabled:pointer-events-none "
                  ></textarea>
                </div>
              </div>

              <div className="mt-6 grid">
                <button
                  type="submit"
                  className="w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-primary-500 text-white hover:bg-primary-600 disabled:opacity-50 disabled:pointer-events-none"
                >
                  {pending ? "Enviando..." : "Enviar"}
                </button>
              </div>

              <div className="mt-3 text-center">
                <p className="text-sm text-gray-500 ">
                  Estamos aceptando inscripciones por bloques, te daremos acceso
                  tan pronto nos sea posible.
                </p>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}
